import * as React from 'react';

import Seo from '../components/Seo';
import PageHeader from '../components/PageHeader';
import Edges from '../components/Edges';
import Logo from '../images/icons/logo.svg';

const PrivacyPolicyPage = (props) => {
    const { uri } = props;
    return (
        <>
            <Seo
                title="The Hub for BC's Public Trades Training Programs & Schedules"
                description="Trades Training BC is a consortium of 15 public post-secondary educational institutions created to promote trades training in British Columbia."
            />
            <PageHeader title={'Privacy Policy'} breadcrumbs={[{ label: 'Privacy Policy', url: uri }]} />

            <Edges size={'md'}>
                <div className={'flex flex-col justify-center px-[20px] md:flex-row md:px-[0px]'}>
                    <div className={'mr-[30px] w-[130px] min-w-[130px]'}>
                        <Logo className={'mb-[20px]'} />
                    </div>

                    <div className="text-[#414141] lg:w-11/12">
                        <p className="mb-[10px] ">
                            Protecting privacy on the Internet is crucial to Internet-based business and the Internet economy. Trades Training Consortium of BC has created this Privacy Statement to demonstrate our
                            commitment to the individual's right to privacy. This Privacy Statement covers personal information, non-personal data collection and aggregate reporting.
                        </p>
                        <p className="mb-[20px] ">
                            This Privacy Statement applies to all Trades Training Consortium of BC owned websites and domains. We bind our employees to observe your privacy and confidentiality rights.
                        </p>

                        <p className="mb-[10px] font-bold">Links to non- Trades Training Consortium of BC websites</p>
                        <p className="mb-[20px]">
                            The Trades Training Consortium of BC website may provide links to third-party websites for your convenience and information. If you access those links, you will leave the Trades Training
                            Consortium of BC website. Trades Training Consortium of BC does not control those sites or their privacy practices, which may differ from the Trades Training Consortium of BC. We do not
                            endorse or make any representations about third-party websites. The Trades Training Consortium of BC Privacy Statement does not cover the personal data you choose to give to unrelated third
                            parties. We encourage you to review the privacy policy of any company before submitting your personal information.
                        </p>

                        <p className="mb-[10px] font-bold">Consent</p>
                        <p className="mb-[20px]">
                            By using this Web site, you consent to the collection and use of information as described here. If we decide to make changes to this Privacy Policy, we will post the changes on this page so
                            that you will always know what information we collect, and how we use it.
                        </p>

                        <p className="mb-[10px] font-bold">Use and Purpose of Collected Personal Data</p>
                        <p className="mb-[5px]">
                            On our Web site, we only store personal information relating to you with your permission. You may choose to reveal information to us if, for example, you register, or complete a survey. Trades
                            Training Consortium of BC may use the information in connection with processing your inquiries or to help improve our communication.
                        </p>
                        <p className="mb-[20px]">
                            We would only share such information with third parties outside the Trades Training Consortium of BC with your express permission or as required by the applicable law. Information is used
                            solely for the purposes described below.
                        </p>

                        <p className="mb-[10px] font-bold">What Information Do We Collect and Why?</p>
                        <p className="mb-[5px] font-bold">IP Addresses</p>
                        <p className="mb-[10px]">
                            We use IP addresses to help diagnose problems, to administer our Web site, and to gather demographic information. When you visit our site, we recognize only your domain name and not your
                            e-mail address. We will see your e-mail address only if you give it to us by filling out a form or by sending us an e-mail message.
                        </p>
                        <p className="mb-[5px] font-bold">Cookies</p>
                        <p className="mb-[20px]">
                            We use cookies to determine usage patterns of our web site. A "cookie" is a small data file transferred by a website to your computer’s hard drive. Trades Training Consortium of BC sends
                            cookies when you surf our site. Accepting the cookies used on our site does not give us access to your personal information, but we may use the cookies to identify your computer.
                        </p>

                        <p className="mb-[5px]">Cookies are typically classified as either "session" cookies or "permanent" cookies.</p>

                        <p className="mb-[20px]">
                            <ul className="list-disc pl-[30px]">
                                <li>
                                    "Session" cookies do not stay on your computer after you leave our website or close your browser. The aggregate information collected permits us to analyze traffic patterns on our
                                    site. This can enable us over time to provide a better experience on our site by improving content or personalization and making our site easier to use.
                                </li>
                                <li>
                                    "Permanent" cookies are those that remain on your computer. They are used to facilitate shopping, personalization and registration services. For example, cookies can keep track of what
                                    you have selected to purchase as you continue to shop and allow you to enter your password only once on Web pages where a login is required. The user can manually remove "Permanent"
                                    cookies.
                                </li>
                            </ul>
                        </p>

                        <p className="mb-[5px]">
                            Most browsers automatically accept cookies by default, but you can usually refuse cookies or selectively accept certain cookies by adjusting the preferences in your browser. If you turn off
                            cookies, there may be some features of our site that will not be available to you and some Web pages may not display properly. You can find information on popular browsers and how to adjust
                            your cookie preferences at the following websites:
                        </p>

                        <p className="mb-[20px]">
                            <ul className="list-disc pl-[30px]">
                                <li>
                                    Microsoft Internet Explorer:
                                    <a className={'text-[#2A6496]'} href={'https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies'}>
                                        {' '}
                                        https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies{' '}
                                    </a>
                                </li>

                                <li>
                                    Mozilla Firefox:
                                    <a className={'text-[#2A6496]'} href={'http://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer'}>
                                        {' '}
                                        https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer
                                    </a>
                                </li>

                                <li>
                                    Google Chrome:
                                    <a className={'text-[#2A6496]'} href={'https://support.google.com/chrome/answer/95647?hl=en'}>
                                        {' '}
                                        https://support.google.com/chrome/answer/95647?hl=en
                                    </a>
                                </li>
                            </ul>
                        </p>

                        <p className="mb-[5px] font-bold">E-mail Addresses</p>
                        <p className="mb-[20px]">If you choose to provide your e-mail address via our Contact page, you have implied consent to be contacted in return via e-mail.</p>

                        <p className="mb-[5px] font-bold">
                            Similarly, if you choose to provide your e-mail address and other contact information regarding our Jobs Board or any Program in the trades schedule, you have implied consent to be contacted
                            in return, and we may communicate with you via e-mail. Note that Program Information e-mails are automatically sent directly to the institutions offering the programs in question so that they
                            may contact you in return via e-mail.
                        </p>

                        <p className="mb-[20px]">If you choose to provide your e-mail address via our Contact page, you have implied consent to be contacted in return via e-mail.</p>

                        <p className="mb-[5px] font-bold">Inquiries</p>
                        <p className="mb-[20px]">
                            If you have any questions about this privacy statement - please e-mail us at{' '}
                            <a className={'text-[#2A6496]'} href={'/contact'}>
                                {' '}
                                support
                            </a>
                        </p>
                    </div>
                </div>
            </Edges>
        </>
    );
};

export default PrivacyPolicyPage;
